<template>
  <div id="home">
    <el-button icon="el-icon-back" @click="back" class="backBtn">返回</el-button>
    <div class="title">隐患上报</div>
    <el-form ref="form" label-width="80px">

      <el-form-item label="上报类型:">
        <el-radio-group v-model="reportType">
          <el-radio label="安全隐患"></el-radio>
          <el-radio label="故障报修"></el-radio>
        </el-radio-group>
      </el-form-item>

      <el-form-item label="上传图片:">
        <el-upload action="" multiplt :auto-upload="false" accept="jpg,jpeg,png,PNG" list-type="picture-card" :file-list="list" :before-upload="beforeUpload" :on-change="uploadChange" :class="{hide:hideUploadBtn}" :limit="3">
          <i class="el-icon-plus"></i>
        </el-upload>

      </el-form-item>

      <el-form-item label="位置信息:" style="margin-right:10px">
        <el-input v-model="location"></el-input>
      </el-form-item>

      <el-form-item label="描述信息:" style="margin-right:10px">
        <el-input type="textarea" v-model="describe" placeholder="请输入详情" maxlength="100" show-word-limit :rows="4"></el-input>
      </el-form-item>

      <!-- <el-form-item> -->
      <el-button type="primary" @click="submit" class="submitBtn" :loading="isLoading">上报</el-button>
      <!-- </el-form-item> -->
    </el-form>
  </div>
</template>

<script>
import scanCodeAlarm from "../../api/manageApi/scanCodeAlarm";
export default {
  data() {
    return {
      scanCodeId: '', //二维码id
      phone: '19912162105', //上报人手机号
      location: '', //二维码所在位置
      reportType: '', //上报类型
      describe: '', //上报描述
      list: [], //图片集合
      hideUploadBtn: false, //上传图片按钮
      isLoading: false,//过渡样式
    }
  },
  created() {
    this.scanCodeId = this.$route.params.scanCodeId;
    this.phone = this.$route.params.phone;
    this.location = this.$route.params.location;
  },
  methods: {
    //上传图片前
    beforeUpload(file) {
    },
    // 动态绑定数据并判断最多上传3张图，超过时隐藏上传按钮
    uploadChange(file, fileList) {
      this.list = fileList
      //当上传的图片数量达到三张时，调用此方法隐藏上传按钮
      this.hideUploadBtn = fileList.length >= 3;
    },
    back() {
      this.$router.go(-1);
    },
    //上报按钮
    submit() {
      //判断是否为空
      if (this.reportType == "" || this.describe == "" || this.list.length == 0) {
        this.$message({
          type: "error",
          message: "必填项不能为空!"
        })
      } else {
        this.isLoading = true;
        const formData = new FormData();
        formData.append("scanCodeId", this.scanCodeId);
        formData.append("phone", this.phone);
        formData.append("location", this.location);
        // let multipartFileList = [];
        for (let i = 0; i < this.list.length; i++) {
          // multipartFileList.push(this.list.raw);
          formData.append("multipartFileList", this.list[i].raw)
        }

        formData.append("type", this.reportType);
        formData.append("reportNote", this.describe);
        scanCodeAlarm.sendForHelp(formData).then((res) => {
          console.log(res);
          if ("上报成功" == res.data.message) {
            // this.$message({
            //   type: "success",
            //   message: "上报成功"
            // })
            this.$alert('上报成功！点击【确认】按钮继续。', '上报成功！', {
              confirmButtonText: '确定',
              showClose: false,
            }).then(() => {
              this.isLoading = false;
              this.back();
            })

          } else {
            this.$message({
              type: "error",
              message: res.data.message
            })
            this.isLoading = false;
          }
        })
      }
    },
  }
}
</script>

<style socped lang="scss">
#home {
  background: #fff;
  width: 24.8%;
}
.backBtn {
  border: none;
  z-index: 1;
  position: absolute;
  top: 3px;
}
.title {
  font-size: 22px;
  text-align: center;
  padding: 10px 0;
  border-bottom: 1px solid rgb(146, 198, 240);
  box-shadow: 0 0 1px 0 3px rgb(144, 224, 235);
}
/* 隐藏上传组件 */
.hide {
  .el-upload--picture-card {
    display: none !important;
  }
}
.el-form-item {
  margin-top: 10px;
}
.el-upload {
  margin-top: 5px;
  width: 80px;
  height: 80px;
  line-height: 80px;
}
.el-upload-list--picture-card .el-upload-list__item {
  margin-top: 5px;
  width: 80px;
  height: 80px;
  line-height: 80px;
}
.submitBtn {
  margin-top: 10px;
  width: 100%;
  // margin: 0 auto;
}
// .el-upload-list--picture-card .el-upload-list__item-thumbnail{
//     width: 100px;
//     height: 100px;
//     line-height: 100px;
// }
.avatar {
  width: 100px;
  height: 100px;
}
</style>
