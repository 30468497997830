import request from '@/../utils/request'
const api_name = '/iotechserver/ScanCodeAlarmController'
export default {

    //紧急求助/安全隐患
    sendForHelp(formData) {
        return request({
            headers: {
                'Content-Type': 'multipart/form-data; boundary=----WebKitFormBoundarynl6gT1BKdPWIejNq'
            },
            url: `${api_name}/sendForHelp`,
            method: 'post',
            data: formData
        })
    },

    //火警
    sendFireAlarm(scanCodeId,location,phone,type) {
        return request({
            url: `${api_name}/sendFireAlarm`,
            method: 'post',
            data: {
                id:scanCodeId,
                location:location,
                phone:phone,
                type:type,
            }
        })
    },

    //逃生路线(CRT)
    getQrCodeInfo(scanCodeId){
        return request({
            url: `${api_name}/getQrCodeInfo/${scanCodeId}`,
            method: 'get'
        })
    }

    
}